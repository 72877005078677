import React from 'react';
import { Layout } from '@/layout';
import SponsorInschrijven from '@/pages/inschrijven-sponsoren';

export default function Home() {
  return (
    <Layout>
      <SponsorInschrijven />
    </Layout>
  );
}
